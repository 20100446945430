import React from "react";
import { StyledTable, StyledTableRow } from "@cortexglobal/lens-components";
import { Link } from "react-router-dom";
import { Button, Panel } from "@cortexglobal/rla-components";
import styled from "styled-components";

const PanelHeader = styled.div`
    background: #002a5b;
    color: white;
    padding: 0.5em 1em;
`;

const PanelBody = styled.div``;

const StyledPanel = styled(Panel)`
    padding: 0;
`;

const BoldTd = styled.td`
    width: 350px;
    font-weight: bold;
    padding-right: 1em;
    text-align: left;
`;

const CompanyData = ({ companyData }) => {
    return (
        <>
            <StyledPanel>
                <PanelHeader>Customer Details</PanelHeader>
                <PanelBody>
                    <StyledTable style={{ width: "100%", margin: "0" }}>
                        <tbody>
                            <StyledTableRow>
                                <BoldTd>Customer ID:</BoldTd>
                                <td>{companyData.id}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Distrigo Number:</BoldTd>
                                <td>{companyData.trade_team_id}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Customer Name:</BoldTd>
                                <td>{companyData.trade_team_customer}</td>
                            </StyledTableRow>
                            {companyData.alternative_customer_name && (
                                <StyledTableRow>
                                    <BoldTd>Alternative Customer Name:</BoldTd>
                                    <td>
                                        {companyData.alternative_customer_name}
                                    </td>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                <BoldTd>Customer Distrigo Type:</BoldTd>
                                <td>{companyData.trade_team_customer_type}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Primary Customer Type:</BoldTd>
                                <td>{companyData.type}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Customer Status:</BoldTd>
                                <td>{companyData.customer_status}</td>
                            </StyledTableRow>
                            {companyData.cluster && (
                                <StyledTableRow>
                                    <BoldTd>Customer Cluster:</BoldTd>
                                    <td>{companyData.cluster}</td>
                                </StyledTableRow>
                            )}
                            <StyledTableRow>
                                <BoldTd>Customer Classification:</BoldTd>
                                <td>{companyData.classification}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Phone Number:</BoldTd>
                                <td>{companyData.phone}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Address:</BoldTd>
                                <td>{companyData.address_1}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Postcode:</BoldTd>
                                <td>{companyData.postcode}</td>
                            </StyledTableRow>
                        </tbody>
                    </StyledTable>
                </PanelBody>
            </StyledPanel>
            <StyledPanel>
                <PanelHeader>Sales Data</PanelHeader>
                <PanelBody>
                    <StyledTable style={{ width: "100%", margin: "0" }}>
                        <tbody>
                            <StyledTableRow>
                                <BoldTd>Total Sale Quantity:</BoldTd>
                                <td>
                                    {
                                        companyData.counts
                                            .total_sales_total_quantity
                                    }
                                </td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Total Sales (All Time):</BoldTd>
                                <td>
                                    {companyData.counts
                                        .total_sales_total_price || "-"}
                                </td>
                            </StyledTableRow>
                        </tbody>
                    </StyledTable>
                </PanelBody>
            </StyledPanel>
            <StyledPanel>
                <PanelHeader>PERKS</PanelHeader>

                <PanelBody>
                    <StyledTable
                        style={{
                            width: "100%",
                            margin: "0",
                        }}
                    >
                        <tbody>
                            <StyledTableRow>
                                <BoldTd>PERKS Status:</BoldTd>
                                <td>{companyData.perks_status}</td>
                            </StyledTableRow>
                            <StyledTableRow>
                                {companyData.is_activated_for_perks ? (
                                    <BoldTd>PERKS Type:</BoldTd>
                                ) : (
                                    <BoldTd>Eligible for PERKS Type:</BoldTd>
                                )}
                                <td>{companyData.perks_type}</td>
                            </StyledTableRow>

                            {companyData.activated_at && (
                                <StyledTableRow>
                                    <BoldTd>Activation Date:</BoldTd>
                                    <td>
                                        {new Date(
                                            companyData.activated_at
                                        ).toLocaleString()}
                                    </td>
                                </StyledTableRow>
                            )}

                            {companyData.available_points !== undefined && (
                                <StyledTableRow>
                                    <BoldTd>Available Points:</BoldTd>
                                    <td>{companyData.available_points}</td>
                                </StyledTableRow>
                            )}
                        </tbody>
                    </StyledTable>
                </PanelBody>
            </StyledPanel>

            <StyledPanel>
                <PanelHeader>Primary Contact</PanelHeader>
                <PanelBody>
                    <StyledTable
                        style={{
                            width: "100%",
                            margin: "0",
                        }}
                    >
                        <tbody>
                            <StyledTableRow>
                                <BoldTd>Name:</BoldTd>
                                {companyData.primary_contact ? (
                                    <td>
                                        {companyData.primary_contact.first_name}{" "}
                                        {companyData.primary_contact.last_name}
                                    </td>
                                ) : (
                                    <td>-</td>
                                )}
                            </StyledTableRow>

                            <StyledTableRow>
                                <BoldTd>Job Title:</BoldTd>
                                <td>
                                    {companyData.primary_contact?.job_title ||
                                        "-"}
                                </td>
                            </StyledTableRow>

                            <StyledTableRow>
                                <BoldTd>Email:</BoldTd>
                                <td>
                                    {companyData.primary_contact?.email || "-"}
                                </td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Phone Number:</BoldTd>
                                <td>
                                    {companyData.primary_contact?.phone || "-"}
                                </td>
                            </StyledTableRow>
                            <StyledTableRow>
                                <BoldTd>Mobile Number:</BoldTd>
                                {companyData.primary_contact ? (
                                    <td>
                                        {
                                            companyData.primary_contact
                                                .mobile_phone
                                        }
                                    </td>
                                ) : (
                                    <td>-</td>
                                )}
                            </StyledTableRow>
                        </tbody>
                    </StyledTable>
                    {companyData.primary_contact && (
                        <div
                            style={{
                                padding: "1rem",
                                textAlign: "center",
                            }}
                        >
                            <Link
                                to={`/contacts/${companyData.primary_contact.id}`}
                            >
                                <Button>View Contact</Button>
                            </Link>
                        </div>
                    )}
                </PanelBody>
            </StyledPanel>

            {companyData.weather && (
                <StyledPanel>
                    <PanelHeader>Current Weather Information</PanelHeader>
                    <PanelBody>
                        <StyledTable
                            style={{
                                width: "100%",
                                margin: "0",
                            }}
                        >
                            <tbody>
                                <StyledTableRow>
                                    <BoldTd>Last Updated:</BoldTd>
                                    <td>
                                        {new Date(
                                            companyData.weather.updated_at
                                        ).toDateString()}
                                    </td>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <BoldTd>Temperature:</BoldTd>
                                    <td>{companyData.weather.temperature}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <BoldTd>Feels Like:</BoldTd>
                                    <td>{companyData.weather.feels_like}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <BoldTd>Temp Min:</BoldTd>
                                    <td>{companyData.weather.temp_min}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <BoldTd>Temp Max:</BoldTd>
                                    <td>{companyData.weather.temp_max}</td>
                                </StyledTableRow>
                                <StyledTableRow>
                                    <BoldTd>Humidity:</BoldTd>
                                    <td>{companyData.weather.humidity}</td>
                                </StyledTableRow>
                            </tbody>
                        </StyledTable>
                    </PanelBody>
                </StyledPanel>
            )}
        </>
    );
};

export default CompanyData;
